<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false"> Pesquisas de Satisfação </hero-bar>
    <section class="section is-main-section">
      <ModalInserSurvey
        v-if="isModalOpen"
        :surveyUpdating="surveyUpdating"
        :wwPhones="phones"
        v-on:closeModal="closeModal"
        :isComponentModalActive="isModalOpen"
        v-on:addSurvey="handleAddSurvey"
        v-on:updateSurvey="handleUpdateSurvey"
        :canUpdate="getIsAdmin || getIsSupervisorAllDepartments"
      />
      <SurveysList
        :surveys="allSatisfactionSurveys"
        :canUpdate="getIsAdmin || getIsSupervisorAllDepartments"
        v-on:updateSurvey="openUpdateSurvey"
        v-on:deleteSurvey="removeSurvey"
      />
      <b-loading
        v-if="fetchingSatisfactionSurveys"
        :is-full-page="true"
        v-model="fetchingSatisfactionSurveys"
        :can-cancel="false"
      ></b-loading>
      <FabButton
        v-if="getIsAdmin || getIsSupervisorAllDepartments"
        iconClass="fas fa-pen-fancy"
        title="Nova Pesquisa"
        v-on:addClick="handleAddClick"
      />
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import FabButton from '@/components/FabButton.vue'
import SurveysList from '@/components/satisfactionSurveys/SurveysList.vue'
import ModalInserSurvey from '@/components/modals/ModalInsertSurvey.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SatisfactionSurveys',
  components: {
    HeroBar,
    TitleBar,
    FabButton,
    SurveysList,
    ModalInserSurvey
  },
  data () {
    return {
      isModalOpen: false,
      surveyUpdating: {},
      phones: []
    }
  },
  computed: {
    ...mapGetters([
      'allSatisfactionSurveys',
      'allSentSurveys',
      'fetchingSatisfactionSurveys',
      'getIsSupervisorAllDepartments',
      'getIsAdmin',
      'allSettingsEnabled'
    ]),
    titleStack () {
      return ['Cadastros', 'Pesquisas de Satisfação']
    }
  },
  methods: {
    ...mapActions([
      'addSurvey',
      'fetchSatisfactionSurveys',
      'fetchSentSurveys',
      'updateSurvey',
      'deleteSurvey',
      'fetchSettings'
    ]),
    async handleAddClick () {
      await this.fetchSettings()
      this.surveyUpdating = null
      this.isModalOpen = true
    },
    openUpdateSurvey (survey) {
      const sentSurvey = this.allSentSurveys.filter(s => s === survey.id)
      this.surveyUpdating = JSON.parse(JSON.stringify(survey))
      this.surveyUpdating.sent = sentSurvey.length > 0
      this.isModalOpen = true
    },
    async handleAddSurvey (survey) {
      this.closeModal()
      await this.addSurvey(survey)
    },
    async handleUpdateSurvey (survey) {
      this.closeModal()
      await this.updateSurvey(survey)
    },
    async removeSurvey (surveyId) {
      this.deleteSurvey(surveyId)
        .then(() => this.$buefy.toast.open('Pesquisa excluída com sucesso!'))
        .catch(() => {
          this.$buefy.toast.open(
            'Não é possível excluir esta pesquisa, pois ela já foi enviada!'
          )
        })
    },
    closeModal () {
      this.surveyUpdating = {}
      this.isModalOpen = !this.isModalOpen
    }
  },
  async created () {
    await this.fetchSettings()
    this.fetchSatisfactionSurveys()
    this.fetchSentSurveys()
    for (const s of [...this.allSettingsEnabled]) {
      this.phones.push({ phone: s.phone, color: s.color, name: s.name })
    }
  }
}
</script>
