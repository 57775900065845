<template>
  <div>
    <div v-if="surveys.length > 0">
      <b-table :data="surveys" :bordered="false" :striped="true" :narrowed="false" :hoverable="true"
        :loading="false" :focusable="false" :mobile-cards="true" scrollable>

        <b-table-column field="question" label="Pergunta" v-slot="props" width="40%">
          {{ props.row.question }}
        </b-table-column>

        <b-table-column field="type" label="Tipo" v-slot="props">
          {{ props.row.type === "ATT"
            ? "Atendente"
            : props.row.type === "DPT"
              ? "Departamento"
              : props.row.type === "OPT"
                ? "Opção de menu"
                : props.row.type === "GEN"
                  ? "Geral"
                  : ''
          }}
        </b-table-column>

        <b-table-column field="ww_phone" label="Número" v-slot="props" class="phone-column">
          <PhoneTag v-if="props.row.phone_number" :phone="props.row.phone_number" :name="props.row.phone_name" :color="props.row.phone_color" :width="true" />
        </b-table-column>

        <b-table-column field="always_send" label="Sempre enviar" v-slot="props">
          <YesNoTag :isYes="props.row.always_send" />
        </b-table-column>

        <b-table-column field="enabled" label="Status" v-slot="props">
          <IsActiveTag :active="props.row.enabled" />
        </b-table-column>

        <b-table-column field="update" label="Editar" v-slot="props">
          <b-button type="is-warning" icon-right="pencil" @click="updateSurvey(props.row)" />
        </b-table-column>

        <b-table-column field="delete" label="Excluir" v-slot="props">
          <b-button type="is-danger" icon-right="delete" @click="confirmDelete(props.row.id)" />
        </b-table-column>
      </b-table>
    </div>
  </div>
</template>

<script>

import YesNoTag from '../commons/YesNoTag.vue'
import IsActiveTag from '../commons/IsActiveTag.vue'
import PhoneTag from '../commons/PhoneTag.vue'
import mixin from '../../utils/mixins'

export default {
  name: 'SurveysList',
  props: {
    surveys: {
      type: Array,
      required: true
    }
  },
  components: {
    YesNoTag,
    IsActiveTag,
    PhoneTag
  },
  mixins: [mixin],
  methods: {
    updateSurvey (survey) {
      this.$emit('updateSurvey', survey)
    },
    deleteSurvey (id) {
      this.$emit('deleteSurvey', id)
    },
    confirmDelete (id) {
      this.$buefy.dialog.confirm({
        title: 'Excluir pesquisa',
        message: 'Deseja <b>excluir</b> esta pesquisa?',
        confirmText: 'Excluir pesquisa',
        type: 'is-danger',
        hasIcon: true,
        cancelText: 'Cancelar',
        onConfirm: () => this.$emit('deleteSurvey', id)
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
