<template>
  <div>
    <!-- <b-tabs class="tabs-settings">
      <b-tab-item label="Atendentes" icon="account"> -->
        <b-message class="warning-message" type="is-warning">
          O <b>tempo online permitido fora do chat</b> determina quanto tempo
          o atendente pode ficar online ou ausente e fora do chat. Ou seja,
          se um usuário passar mais tempo que o permitido online ou ausente fora
          do chat, ele terá seu <b>status automaticamente alterado para offline</b>
        </b-message>
        <b-field label="Tempo online permitido fora do chat (em minutos)">
          <b-input type="number" min="1" v-model="setOfflineInterval"></b-input>
        </b-field>
      <!-- </b-tab-item>
    </b-tabs> -->
    <div class="level-right">
      <button
        @click="saveSettings"
        class="button is-success mt-2 mb-2"
        slot="level-right"
      >
        Salvar configurações
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GeneralConfigs',
  props: {
    settings: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      setOfflineInterval: 30
    }
  },
  created () {
    this.setOfflineInterval = this.settings.set_offline_interval
  },
  methods: {
    saveSettings () {
      if (this.setOfflineInterval < 1) {
        this.$buefy.dialog.alert({
          title: 'Intervalo muito curto',
          message: 'O tempo mínimo que um usuário ficar fora do chat é de 1 minuto.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        return
      }

      this.$emit('saveSettings', {
        setOfflineInterval: this.setOfflineInterval
      })
    }
  }
}
</script>
<style>
  .tabs-settings .is-active a {
    border-bottom-color: #00A48D !important;
    color: #00A48D !important;
  }

  .tabs-settings .phone-color {
    border-radius: 5px;
    border: 0px
  }

  .tabs-settings .phone-color input[type="color"] {
    background: none;
  }
</style>
