<template>
  <div class="phone-container" :class="{'container-width': width}">
    <div v-if="color" class="tag" :class="{'tag-width': width}" :style="{ backgroundColor: color, color: 'white' }">
      <p>{{ name ? name : phone }}</p>
    </div>
    <div v-else class="tag">
      <p>{{ name ? name : phone }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PhoneTag',
  props: {
    phone: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: false
    },
    color: {
      type: String,
      required: false
    },
    width: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style scoped>

.phone-container .tag{
  justify-content: left;
  display: table;
  table-layout: fixed;
  background-color: transparent;
}

.phone-container .tag p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align:center;
  vertical-align: middle;
  display: table-cell;
}

.container-width {
  width: 90%;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}
.tag-width {
  width: 100%;
}

</style>
