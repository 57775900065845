<template>
    <div>
      <section>
        <b-modal :active="isComponentModalActive" @close="closeModal">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
              <p v-if="surveyUpdating" class="modal-card-title">
                Atualizar pesquisa de satisfação
              </p>
              <p v-else class="modal-card-title">
                Insira a pesquisa de satisfação a ser cadastrada
              </p>
            </header>
            <section class="modal-card-body">
              <b-field label="Pergunta">
                <b-input
                  type="text"
                  v-model="question"
                  placeholder="Pergunta"
                  required
                  expanded
                  :disabled="surveyUpdating && surveyUpdating.sent"
                  @input="checkQuestion()"
                >
                </b-input>
              </b-field>
              <b-field label="Tipo (O que a pesquisa irá avaliar?)">
                <b-select
                  placeholder="Selecione uma opção"
                  v-model="type"
                  required
                  expanded
                  :disabled="surveyUpdating && surveyUpdating.sent"
                >
                  <option value="GEN" key="GEN">Geral (a empresa)</option>
                  <option value="ATT" key="ATT">Atendente</option>
                  <option value="DPT" key="DPT">Departamento</option>
                  <option value="OPT" key="OPT">Opção de menu</option>
                </b-select>
              </b-field>
              <b-field label="Mensagem para resposta inválida (Enviada caso não seja respondido um número de 0 a 10)">
                <b-input
                  type="text"
                  v-model="message_invalid_answer"
                  placeholder="Resposta inválida."
                >
                </b-input>
              </b-field>
              <b-field label="Número para envio (deixe vazio para que qualquer número envie a pesquisa)">
                <b-select
                  placeholder=""
                  v-model="wwPhoneSelected"
                  expanded
                >
                  <option :value="null"></option>
                  <option
                    v-for="setting in wwPhones"
                    :value="setting.phone"
                    :key="setting.phone"
                  >
                    {{ setting.name ? setting.name + ' - ' + setting.phone : setting.phone }}
                  </option>
                </b-select>
              </b-field>
              <div class="field">
                <b-checkbox v-model="always_send">Sempre enviar pesquisa</b-checkbox>
              </div>
              <div class="field">
                <b-checkbox v-model="enabled">Pesquisa ativa</b-checkbox>
              </div>
            </section>
            <footer class="modal-card-foot">
              <button class="button" type="button" @click="closeModal">
                Fechar
              </button>
              <button
                v-if="!!surveyUpdating"
                class="button is-primary"
                @click="updateSurvey"
              >
                Atualizar
              </button>
              <button v-else class="button is-primary" @click="addSurvey">
                Gravar
              </button>
            </footer>
          </div>
        </b-modal>
      </section>
    </div>
  </template>

<script>
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  name: 'ModalInsertSurvey',
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    surveyUpdating: {
      type: Object,
      required: false
    },
    wwPhones: {
      type: Array,
      required: false
    }
  },
  data () {
    return {
      question: '',
      type: 'GEN',
      always_send: false,
      enabled: true,
      message_invalid_answer: '',
      updateQuestion: false,
      wwPhoneSelected: ''
    }
  },
  async created () {
    if (this.surveyUpdating) {
      this.question = this.surveyUpdating.question
      this.type = this.surveyUpdating.type
      this.always_send = this.surveyUpdating.always_send
      this.message_invalid_answer = this.surveyUpdating.message_invalid_answer
      this.enabled = this.surveyUpdating.enabled
      this.wwPhoneSelected = this.surveyUpdating.phone_number
    }
  },
  methods: {
    addSurvey () {
      if (this.question === '' || !this.question) {
        this.$buefy.dialog.alert({
          title: 'Pesquisa incompleta',
          message:
            'É necessário informar uma pergunta!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }

      this.$emit('addSurvey', {
        question: this.question,
        type: this.type,
        always_send: this.always_send,
        message_invalid_answer: this.message_invalid_answer,
        enabled: this.enabled,
        ww_phone: this.wwPhoneSelected
      })
    },
    closeModal () {
      this.$emit('closeModal')
    },
    updateSurvey () {
      if (this.question === '' || !this.question) {
        this.$buefy.dialog.alert({
          title: 'Pesquisa incompleta',
          message:
            'É necessário informar uma pergunta!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }

      this.emitUpdateSurvey({
        id: this.surveyUpdating.id,
        question: this.question,
        type: this.type,
        always_send: this.always_send,
        message_invalid_answer: this.message_invalid_answer,
        enabled: this.enabled,
        ww_phone: this.wwPhoneSelected
      })
    },
    emitUpdateSurvey (survey) {
      this.$emit('updateSurvey', survey)
    },
    checkQuestion () {
      if (this.surveyUpdating && this.question !== this.surveyUpdating.question) {
        this.updateQuestion = true
      } else {
        this.updateQuestion = false
      }
    }
  }
}
</script>

<style scoped>
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}
</style>
