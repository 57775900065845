<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <section class="section is-main-section">
      <b-loading v-if="fetchingGenSettings" :is-full-page="true" v-model="fetchingGenSettings" :can-cancel="false"></b-loading>
      <Configs
        v-else
        :settings='allGenSettings'
        v-on:saveSettings="saveSettings"
      />
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import { mapActions, mapGetters } from 'vuex'
import Configs from '@/components/settings/GeneralConfigs.vue'
export default {
  name: 'GeneralConfigurations',
  components: {
    TitleBar,
    Configs
  },
  computed: {
    ...mapGetters(['allGenSettings', 'fetchingGenSettings']),
    titleStack () {
      return ['Configurações', 'Configurações gerais']
    }
  },
  methods: {
    ...mapActions(['saveGenSettings', 'fetchGenSettings']),
    saveSettings (data) {
      this.saveGenSettings(data)
    }
  },
  async created () {
    await this.fetchGenSettings()
  }
}
</script>
